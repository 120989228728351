import React from "react";
import { APP_ID, APP_SECRET } from "../Constants";
import "./ChatApp.style.css";
import { APP_NAME } from "../Constant";
import { useParams } from "react-router-dom";

// Integrate the SDK
import {
  ZIMKitManager,
  Common,
  ZIMKitChatListVM,
  ZIMKitConversationType,
} from "@zegocloud/zimkit-react";
import "@zegocloud/zimkit-react/index.css";

// The following uses the App instance as an example.

export default function ChatApp() {
  let params = useParams();
  return <ChatAppInside param={params} />;
}

class ChatAppInside extends React.Component {
  constructor(props) {
    super(props);

    let nameWidthId = this.props.param.name.split("-");

    let user_id = this.props.param.name;
    let user_name = nameWidthId[0];
    this.state = {
      appConfig: {
        appID: APP_ID, // The AppID you get from the ZEGOCLOUD admin console.
        serverSecret: APP_SECRET, // The serverSecret you get from ZEGOCLOUD Admin Console.
      },
      // The userID and userName is a strings of 1 to 32 characters.
      // Only digits, letters, and the following special characters are supported: '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '=', '-', '`', ';', '’', ',', '.', '<', '>', '/', '\'
      userInfo: {
        // Your ID as a user.
        userID: `${user_id}`,
        // Your name as a user.
        userName: `${user_name}`,
        // The image you set as a user avatar must be network images. e.g., https://storage.zego.im/IMKit/avatar/avatar-0.png
        userAvatarUrl: "",
      },
    };
  }

  async componentDidMount() {
    try {
      const zimKit = new ZIMKitManager();
      const token = zimKit.generateKitTokenForTest(
        this.state.appConfig.appID,
        this.state.appConfig.serverSecret,
        this.state.userInfo.userID
      );

      document.querySelector(".logo").innerHTML = APP_NAME;

      await zimKit.init(this.state.appConfig.appID);

      await zimKit.connectUser(this.state.userInfo, token);

      // starting conversation
      this.showPeerChat();
    } catch (error) {
      alert(error.message);
    }
  }

  // create chat by clicking
  createChat(userID) {
    ZIMKitChatListVM.getInstance().initWithConversationID(
      userID,
      ZIMKitConversationType.ZIMKitConversationTypePeer
    );
  }

  // start automatic chat
  showPeerChat() {
    const conversationID = "0Admin-1"; // The ID of an existing 1-on-1 chat, also the User ID of the user who has logged into the In-app Chat Kit.
    // Call the initWithConversationID method to create a new chat.
    ZIMKitChatListVM.getInstance().initWithConversationID(
      conversationID,
      ZIMKitConversationType.ZIMKitConversationTypePeer
    );
  }

  render() {
    return (
      <>
        <p>{this.state.userInfo.userName}</p>
        <Common></Common>
      </>
    );
  }
}
