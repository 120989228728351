import ChatAdmin from "./components/ChatAdmin";
import ChatApp from "./components/ChatApp";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/admin/:name" element={<ChatAdmin />}></Route>
          <Route exact path="/user/:name" element={<ChatApp />}></Route>
        </Routes>
      </BrowserRouter>
      {/* <ChatApp /> */}
    </div>
  );
}

export default App;
